import { CARDS_WITH_DISCLAIMER_PROPS } from "data/apply/fourCardsWithDisclaimer";
import { FULL_WIDTH_IMAGE_WITH_TITLE_PROPS } from "data/apply/fullWidthImageWithTitle";
import { HERO_WITH_FORM_PROPS } from "data/apply/heroWithForm";
import { SV_ROAD_TRIP_PROPS } from "data/apply/roadTrip";
import { TIP_CARDS_WITH_TITLE_PROPS } from "data/apply/tipCards";
import { FULL_FRAME_BULLETS_WITH_TITLE_PROPS } from "data/program/bullets";
import Head from "next/head";

import CardsWithDisclaimer from "components/templates/CardsWithDisclaimer";
import FullFrameBulletsWithTitle from "components/templates/FullFrameBulletsWithTitle";
import FullWidthImageWithTitle from "components/templates/FullWidthImageWithTitle";
import HeroWithForm from "components/templates/HeroWithForm";
import SVRoadTrip from "components/templates/SVRoadTrip";
import TipCardsWithTitle from "components/templates/TipCardsWithTitle";

import { SVPage } from "types/theme.types";

const Apply: SVPage = () => {
  return (
    <>
      <Head>
        <link rel="canonical" href="https://sv.academy/apply" key="canonical" />
      </Head>
      <HeroWithForm {...HERO_WITH_FORM_PROPS} />
      <FullFrameBulletsWithTitle {...FULL_FRAME_BULLETS_WITH_TITLE_PROPS} />
      <TipCardsWithTitle {...TIP_CARDS_WITH_TITLE_PROPS} />
      <FullWidthImageWithTitle {...FULL_WIDTH_IMAGE_WITH_TITLE_PROPS} />
      <SVRoadTrip {...SV_ROAD_TRIP_PROPS} />
      <CardsWithDisclaimer {...CARDS_WITH_DISCLAIMER_PROPS} />
    </>
  );
};

Apply.generatePageName = () => "Apply";
Apply.generateExternalPageName = () =>
  "SV Academy | Launch a non-coding career in Tech";
Apply.generateExternalDescription = () =>
  "We’ll train you, help you land a job, and coach you to succeed in a Tech Sales career.";
Apply.navbarProps = {};
Apply.navbarProps.bgColors = {
  alternateColor: "bg-warm-light",
  defaultColor: "bg-warm-light",
};

export default Apply;
